import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import useMedia from 'use-media'
import { usePopper } from 'react-popper'
import { motion, AnimatePresence } from 'framer-motion'
import { Link } from 'gatsby'
import { ModalBase } from '@livechat/design-system'

import Layout from '../../layout/live-coding-layout'
import { SEO, Benefits, Image, Feature, Quantity } from '../../components'
import { decodeQueryString, canUseWindow, toggleScroll } from '../../utils'

import './live-coding.scss'

import SDKsIcon from '../../assets/icons/sdks.svg'
import CloudIcon from '../../assets/icons/cloud.svg'
import TechSupportIcon from '../../assets/icons/tech-support.svg'
import TwitterIcon from '../../assets/icons/twitter.svg'
import LinkedinIcon from '../../assets/icons/linkedin.svg'
import GitHubIcon from '../../assets/icons/github.svg'
import LiveChatIcon from '../../assets/icons/livechat-icon.svg'
import OgImage from '../../assets/images/livechat-live-coding-3-og.png'

import MailChimpForm from '../../components/Forms/LiveCoding'

const HoverMe = ({ children, desc, setOverlay }) => {
  const [referenceElement, setReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const [visible, setVisible] = useState(false)

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'offset', options: { offset: [0, 15] } }],
  })

  const handleClick = e => {
    e.preventDefault()
    setVisible(true)
    setOverlay(true)
  }

  const handleMouseOver = () => {
    setOverlay(true)
    setVisible(true)
  }

  const handleMouseOut = () => {
    setOverlay(false)
    setVisible(false)
  }

  return (
    <>
      <a
        href="#details"
        ref={setReferenceElement}
        onClick={handleClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`hoverMe ${visible && 'hovered'}`}
      >
        <span className="u-text-mark">{children}</span>
      </a>

      <AnimatePresence>
        {visible && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className="popper-wrapper"
          >
            <motion.div
              animate={{
                y: 0,
                opacity: 1,
                rotateX: 0,
              }}
              initial={{
                y: 10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: 4,
              }}
              exit={{
                y: -10,
                opacity: 0,
                transformPerspective: 200,
                rotateX: -4,
              }}
              transition={{ ease: 'easeOut', duration: 0.15 }}
              className={'popperBox'}
            >
              <div className="u-px-xl">{desc}</div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </>
  )
}

const LiveCoding = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'
  const isWide = useMedia({ minWidth: 870 })

  const [overlay, setOverlay] = useState(initialState)
  const [isFormOpen, setIsFormOpen] = useState(action === 'open-form')

  const closeForm = () => {
    setIsFormOpen(false)
    toggleScroll(true)
  }

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      eventDate="May 20th, 2021"
      headerCta={{
        url: 'https://www.youtube.com/watch?v=ei8YG9mLdfI&ab_channel=LiveChat',
        label: 'Watch recording',
      }}
      shouldBlockScroll={isFormOpen}
    >
      <SEO
        title="Live Coding Session with Developer Program"
        description="Take part in a online live coding session with the LiveChat Developer Program team. Leverage your skills in the area of server-side app authorization and have fun fun!"
        image={OgImage}
      />
      <section className="u-text-center u-Pt-xl u-Pb-xl live-coding-bg">
        <Image
          type="privateAppsOverview"
          alt="live-coding"
          className="u-mx-auto"
          wrapperStyles={{ width: isWide ? '50%' : '100%' }}
        />
        <div className="u-Mt-md sm:u-Mt-2xl">
          <h2 className="u-text-p3 u-mt-0 u-mb-sm">
            Learn Server-side App Authorization
          </h2>
          <div className="u-text-p5 u-mt-3xl u-mx-auto event-intro">
            <p className="u-text-left sm:u-text-center">
              Join us for another{' '}
              <HoverMe
                setOverlay={setOverlay}
                desc={
                  <>
                    <p className="u-mb-xs">
                      <strong>Code-along session</strong>
                    </p>
                    <p>
                      Follow along with coding to get the most out of the
                      session.
                    </p>
                  </>
                }
              >
                live coding session
              </HoverMe>{' '}
              hosted by the LiveChat Developer Program. This time, we'll teach
              you LiveChat authorization for server-side applications. It'll
              open you the door to building powerful integrations, for example,
              the ones that make use of webhooks.{' '}
            </p>

            <p className="u-text-left sm:u-text-center second-text">
              {' '}
              Build your{' '}
              <HoverMe
                setOverlay={setOverlay}
                desc={
                  <>
                    <p className="u-mb-xs">
                      <strong>Master LiveChat authorization</strong>
                    </p>
                    <p>
                      After the session, you'll be able to confidently implement
                      an auth flow in your server-side application.
                    </p>
                  </>
                }
              >
                coding skills
              </HoverMe>
              ,<br /> become part of the LiveChat developer{' '}
              <HoverMe
                setOverlay={setOverlay}
                desc={
                  <>
                    <p className="u-mb-xs">
                      <strong>Let's meet on Discord</strong>
                    </p>
                    <p>
                      You'll have a chance to join the community of developers
                      and build strong connections.
                    </p>
                  </>
                }
              >
                community
              </HoverMe>
              ,<br /> and win{' '}
              <HoverMe
                setOverlay={setOverlay}
                desc={
                  <>
                    <p className="u-mb-xs">
                      <strong>Kickstart your journey in Dev Program</strong>
                    </p>
                    <p>
                      Finish the task and submit a pull request with the
                      solution. The first 3 developers will see $50 landing in
                      their Developer Accounts.
                    </p>
                  </>
                }
              >
                prizes
              </HoverMe>
              .
            </p>
          </div>
          <div className="u-flex u-flex-col sm:u-flex-row u-justify-center u-items-center u-text-left u-text-p6 u-p-md">
            <div className="sm:u-mr-xl">
              <div className="u-text-p4 u-mb-md u-mx-auto u-Mt-md sm:u-Mt-xl event-intro-details">
                <strong>When:</strong>{' '}
                <span className="event-intro-info u-text-gray-800">
                  May 20, 2021 at 5 p.m. CEST
                </span>
              </div>
              <div className="u-text-p4 u-mb-md u-mx-auto event-intro-details">
                <strong>Where:</strong>{' '}
                <span className="event-intro-info u-text-gray-800">
                  <a
                    href="https://www.youtube.com/watch?v=ei8YG9mLdfI&ab_channel=LiveChat"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    YouTube
                  </a>
                </span>
              </div>
              <div className="u-text-p4 u-mb-md u-mx-auto u-Mb-md sm:u-Mb-xl event-intro-details">
                <strong>Host:</strong>{' '}
                <span className="event-intro-info u-text-gray-800">
                  <a
                    href="https://www.livechat.com/team/#jakub-sikora"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jakub Sikora
                  </a>
                  , Frontend Lead
                </span>
              </div>
            </div>
            <a
              href="https://www.youtube.com/watch?v=ei8YG9mLdfI&ab_channel=LiveChat"
              style={{ height: '58px' }}
              target="_blank"
              rel="noopener noreferrer"
              className="c-btn v--livechat u-text-white u-text-p5-bold"
            >
              Watch recording
            </a>
          </div>
        </div>
      </section>
      <section className="u-mb-sm u-Pt-sm">
        <h2 className="u-text-p2 u-Mb-md u-text-center">Prizes</h2>
        <Feature
          title={
            <Fragment>Solve a programming task and win a gift card</Fragment>
          }
          description="There are 3 prizes up for grabs: $50 added to your Developer Account in Developer Program. All you need to do is solve a coding task, which will also help you ground your knowledge."
          component={
            <Quantity
              value="3 x $50"
              description="to start in Developer Program"
            />
          }
        />
      </section>
      <section className="o-container u-Mb-xl o-columns v--two">
        <div className="o-container">
          <h2 className="u-text-p4">Event rules</h2>
          <ol className="u-text-p6 u-text-gray-800 u-px-xl">
            <li className="u-mb-sm">
              The session will be live streamed on{' '}
              <a
                href="https://www.youtube.com/watch?v=ei8YG9mLdfI&ab_channel=LiveChat"
                target="_blank"
                rel="noopener noreferrer"
              >
                YouTube
              </a>
              .
            </li>
            <li className="u-mb-sm">
              Participants can win gift cards with the value of $50 each. The
              funds will be transferred to the winners' accounts in{' '}
              <a href="/console/" target="_blank" rel="noopener noreferrer">
                Developer Console
              </a>
              .
            </li>
            <li className="u-mb-sm">
              To withdraw the funds, winners need to have published a paid app
              on the{' '}
              <a
                href="https://www.livechat.com/marketplace"
                target="_blank"
                rel="noopener noreferrer"
              >
                LiveChat Marketplace
              </a>
              .
            </li>
            <li className="u-mb-sm">
              How to win the prize?
              <ul>
                <li>
                  Create an account in{' '}
                  <a href="/console/" target="_blank" rel="noopener noreferrer">
                    Developer Console
                  </a>
                  .
                </li>
                <li>Take part in the live coding session.</li>
                <li>Solve the task given during the session.</li>
                <li>Submit a pull request with the solution.</li>
              </ul>
              <br />
              The first 3 people who submit the correct solution will win the
              prize.{' '}
              <a
                href="https://developers.livechat.com/developer-terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Developer Terms
              </a>{' '}
              apply.
            </li>
          </ol>
        </div>

        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">What you'll learn</span>
          </h2>
          <ol className="u-text-p6 u-text-gray-800 u-px-xl">
            <li className="u-mb-sm">
              How to implement a slightly modified <br />
              <a
                href="https://developers.livechat.com/docs/authorization/authorizing-api-calls/#authorization-code-grant"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat"
              >
                Authorization code grant flow.
              </a>
            </li>
            <li className="u-mb-sm">
              Why <strong>Authorization code grant</strong> is the recommended
              flow for server-side applications.
            </li>
            <li className="u-mb-sm">
              How to use <strong>Direct installation URL</strong> to ease the
              implementation of the authorization flow.
            </li>
            <li className="u-mb-sm">
              How to use LiveChat{' '}
              <a
                href="https://developers.livechat.com/docs/management/configuration-api/"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat"
              >
                webhooks
              </a>{' '}
              and{' '}
              <a
                href="https://developers.livechat.com/docs/messaging/agent-chat-api/"
                target="_blank"
                rel="noopener noreferrer"
                className="u-w-fit u-mt-xs u-mb-xs u-text-p6 u-text-livechat"
              >
                the Agent Chat API
              </a>{' '}
              in your application.
            </li>
            <br />
            As a result, you'll gain the confidence to successfully build
            backend integrations and sell them on the LiveChat Marketplace.
          </ol>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">
            <span className="u-text-mark1">About the host</span>
          </h2>
          <div className="o-feature u-mb-md">
            <div
              className="u-mb-xs u-flex"
              style={{
                justifyContent: isWide ? 'start' : 'center',
                alignItems: isWide ? 'start' : 'center',
              }}
            >
              <Image
                type="jakubSikora"
                className="u-round"
                wrapperStyles={{
                  width: '180px',
                }}
              />
            </div>
            <div
              class="u-ml-auto u-mr-auto u-flex u-flex-col"
              style={{
                textAlign: isWide ? 'left' : 'center',
              }}
            >
              <p className="u-text-p5 u-mb-md">Jakub Sikora</p>
              <p className="u-text-p10 u-text-gray-600">
                Frontend Lead,
                <br />
                Developer Program
              </p>
              <div className="o-column v--three v--sparse">
                <a
                  href="https://twitter.com/JakubSikoraJS"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.linkedin.com/in/jbsikora/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300 u-mr-xl"
                >
                  <LinkedinIcon />
                </a>
                <a
                  href="https://github.com/jakubsikora"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-text-gray-100 hover:u-text-gray-300"
                >
                  <GitHubIcon />
                </a>
              </div>
            </div>
          </div>
          <p className="u-text-p6 u-text-gray-800">
            <a
              href="https://www.livechat.com/team/#jakub-sikora"
              target="_blank"
              rel="noopener noreferrer"
            >
              Jakub Sikora
            </a>{' '}
            is a software engineer with over 11 years of experience in web
            development. He's the frontend lead in the LiveChat Developer
            Program team. He holds weekly programming workshops for his team
            members.
          </p>
        </div>
        <div className="o-container">
          <h2 className="u-text-p4">Who we are</h2>
          <p className="u-text-p6 u-text-gray-800">
            Behind LiveChat, Inc. there is a team of{' '}
            <a
              href="https://www.livechat.com/team/"
              target="_blank"
              rel="noopener noreferrer"
            >
              passionate people
            </a>{' '}
            building online customer service software with online chat, help
            desk software, chatbot, and web analytics capabilities.{' '}
          </p>
          <p className="u-text-p6 u-text-gray-800">
            With a suite of four products (
            <a
              href="https://www.livechat.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LiveChat
            </a>
            ,{' '}
            <a
              href="https://www.chatbot.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              ChatBot
            </a>
            ,{' '}
            <a
              href="https://www.helpdesk.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              HelpDesk
            </a>
            ,{' '}
            <a
              href="https://www.knowledgebase.ai/"
              target="_blank"
              rel="noopener noreferrer"
            >
              KnowledgeBase
            </a>
            ) and their powerful APIs, we power customer communication for
            31,000 companies in 150 countries.
          </p>
          <p className="u-text-p6 u-text-gray-800">
            <Link to="/">LiveChat Platform</Link> is a range of products and
            services that can be used to build a variety of communication tools
            for businesses. Our <Link to="/">Developer Program</Link> and{' '}
            <a
              href="https://www.livechat.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Marketplace{' '}
            </a>
            create an open ecosystem for developers, partners, and customers.
          </p>
        </div>
      </section>

      <section className="u-bg-black2 u-Py-xl u-Mt-2xl u-Mb-md">
        <h2 className="u-text-p3 u-Mb-md u-text-center">Before you go...</h2>
        <Benefits
          content={[
            {
              icon: SDKsIcon,
              label: 'Part 1: Building apps 101',
              description: (
                <>
                  See{' '}
                  <a
                    href="https://www.youtube.com/watch?v=SjqCBgAaEbc&feature=youtu.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    part 1
                  </a>{' '}
                  if you're just starting out with LiveChat apps or need to dust
                  off your knowledge.
                </>
              ),
            },
            {
              icon: TechSupportIcon,
              label: 'Part 2: Authorizing web apps',
              description: (
                <>
                  Watch{' '}
                  <a
                    href="https://youtu.be/FDmLqEiCH60"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    part 2
                  </a>{' '}
                  to get a good understanding of web app authorization in
                  LiveChat.
                </>
              ),
            },
            {
              icon: CloudIcon,
              label: 'Part 3: Authorizing server-side apps',
              description:
                'Join us for part 3, and learn LiveChat authorization for server-side applications.',
            },
          ]}
        />
        <div className="u-text-center">
          <h3 className="u-text-p5 u-Mb-md u-text-center">
            Got a question? Ask us on Discord!
            <br />
          </h3>
          <a
            href="https://discord.gg/gRzwSaCxg4"
            target="_blank"
            rel="noopener noreferrer"
            className="c-btn v--livechat u-text-white u-text-p6-bold"
          >
            Join Discord
          </a>
        </div>
      </section>
      <section className="u-text-center u-My-xl">
        <LiveChatIcon />
        <p className="u-text-p8-bold u-mt-sm outro">
          Live coding session by the <Link to="/">LiveChat Platform</Link>
        </p>
        <p className="u-text-p8 outro">
          Our vision is to help innovators build the future of the
          communication. Sounds interesting?{' '}
          <a href="https://www.livechat.com/careers/">Join us</a>!
        </p>
      </section>

      {isFormOpen && (
        <div className="modal-form">
          <ModalBase
            onClose={closeForm}
            style={{ width: '500px', height: '550px' }}
          >
            <div style={{ margin: 'auto' }}>
              <MailChimpForm />
            </div>
          </ModalBase>
        </div>
      )}
    </Layout>
  )
}

HoverMe.propTypes = {
  desc: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setOverlay: PropTypes.func,
}

export default LiveCoding
